<template>
  <div>
    <i
      class="fas fa-user-check mr-1 fa-lg"
      v-b-tooltip.hover
      :title="dokumenteTooltipText"
      :class="dokumenteIconClass"
    ></i>
    <i
      class="fas fa-suitcase mr-1 fa-lg"
      v-b-tooltip.hover
      :title="reiseleistungenUndListenversandTooltipText"
      :class="reiseleistungenUndListenversandIconClass"
    ></i>
    <i
      class="fas fa-plane mr-1 fa-lg"
      v-b-tooltip.hover
      :title="fluegeTooltipText"
      :class="fluegeIconClass"
      v-if="anreiseart === 'Flug'"
    ></i>
    <i
      v-if="!dachReise"
      class="fas fa-car mr-1 fa-lg"
      :class="carIconClass"
      v-b-tooltip.hover
      title="Transferplanung Vorgangebene"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    vorgang: { type: Object },
    anreiseart: { type: String, default: '' },
    passdatenCheckRequired: { type: Boolean },
    dokumenteCheckRequired: { type: Boolean },
  },
  computed: {
    dachReise() {
      return this.vorgang.hotelleistungen.every(hl => hl.reisetermin.reise.region === 'Dach');
    },
    carIconClass() {
      return this.vorgang.releaseprozessTransferplanungErledigt ? 'text-success' : 'text-danger';
    },
    fluegeIconClass() {
      return this.vorgang.fluegePassend && this.vorgang.flugticketAusgestellt
        ? 'text-success'
        : this.vorgang.fluegePassend || this.vorgang.flugticketAusgestellt
        ? 'text-warning'
        : 'text-danger';
    },
    reiseleistungenUndListenversandIconClass() {
      return this.vorgang.gebuchteReiseleistungListenversandGeprueft === 'NichtGeprueft'
        ? 'text-danger'
        : this.vorgang.gebuchteReiseleistungListenversandGeprueft === 'GeprueftNichtKorrektInArbeit'
        ? 'text-warning'
        : 'text-success';
    },
    dokumenteIconClass() {
      return (this.vorgang.dokumenteGeprueft === 'InArbeit' && this.dokumenteCheckRequired) ||
        (this.vorgang.passdatenGeprueft === 'InArbeit' && this.passdatenCheckRequired) ||
        this.vorgang.teilnehmerGeprueft === 'InArbeit'
        ? 'text-warning'
        : (this.vorgang.dokumenteGeprueft === 'NichtGeprueft' || !this.dokumenteCheckRequired) &&
          (this.vorgang.passdatenGeprueft === 'NichtGeprueft' || !this.passdatenCheckRequired) &&
          this.vorgang.teilnehmerGeprueft === 'NichtGeprueft'
        ? 'text-danger'
        : (this.vorgang.dokumenteGeprueft === 'GeprueftVorhandenKorrekt' || !this.dokumenteCheckRequired) &&
          (this.vorgang.passdatenGeprueft === 'GeprueftVorhandenKorrekt' || !this.passdatenCheckRequired) &&
          this.vorgang.teilnehmerGeprueft === 'GeprueftVorhandenKorrekt'
        ? 'text-success'
        : 'text-warning';
    },
  },
  methods: {
    reiseleistungenUndListenversandTooltipText() {
      return this.reiseleistungenUndListenversandIconClass === 'text-success'
        ? 'Reiseleistungen & Listenversand geprüft'
        : this.reiseleistungenUndListenversandIconClass === 'text-danger'
        ? 'Reiseleistungen & Listenversand nicht geprüft'
        : 'Prüfung Reiseleistungen & Listenversand in Arbeit';
    },
    dokumenteTooltipText() {
      return this.dokumenteIconClass === 'text-success'
        ? 'Teilnehmerdaten geprüft'
        : this.dokumenteIconClass === 'text-danger'
        ? 'Teilnehmerdaten nicht geprüft'
        : 'Prüfung Teilnehmerdaten in Arbeit';
    },
    fluegeTooltipText() {
      return `Flüge ${this.vorgang.fluegePassend ? '' : 'nicht'} geprüft & Flugticket ${
        this.vorgang.flugticketAusgestellt ? '' : 'nicht'
      } ausgestellt`;
    },
  },
};
</script>

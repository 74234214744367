<template>
  <div>
    <span ref="kunden-name-span" v-if="kunde" class="text-nowrap"
      ><b>{{ kunde.vorname }} {{ kunde.nachname }}</b>
    </span>
    <b-popover
      :target="() => $refs['kunden-name-span']"
      triggers="hover"
      placement="bottom"
      boundary="viewport"
    >
      <table class="table table-sm table-borderless">
        <thead>
          <tr>
            <th class="p-0"></th>
            <th class="p-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-muted">Kunden<wbr />nummer:</td>
            <td id="foo">
              <ClickToCopyText :value="kunde.id" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('AUTH.INPUT.EMAIL') }}:</td>
            <td>
              <ClickToCopyText :value="kunde.email" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('COMMON.TELEFONPRIVATSHORT') }}:</td>
            <td>
              <ClickToCopyText :value="telefonPrivat" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('COMMON.TELGESCHAEFTLICHSHORT') }}:</td>
            <td>
              <ClickToCopyText :value="telefonGeschaeftlich" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('COMMON.TELMOBILSHORT') }}:</td>
            <td>
              <ClickToCopyText :value="telefonMobil" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('KUNDEN.DUPLIKATE') }}:</td>
            <td>
              {{ duplicateIds }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-popover>
  </div>
</template>
<style scoped></style>
<script>
import ClickToCopyText from '@/components/common/click-to-copy-text.vue';
export default {
  components: {
    ClickToCopyText,
  },
  props: {
    kunde: { type: Object },
  },
  computed: {
    duplicateIds() {
      const duplicateIdArray = this.kunde?.kunden?.map(kunde => kunde.id).filter(id => id !== this.kunde.id);
      return Array.isArray(duplicateIdArray) && duplicateIdArray.length > 0
        ? duplicateIdArray.join(',')
        : '-';
    },
    telefonPrivat() {
      return this.kunde?.telefonPrivat || '';
    },
    telefonGeschaeftlich() {
      return this.kunde?.telefonGeschaeftlich || '';
    },
    telefonMobil() {
      return this.kunde?.telefonMobil || '';
    },
  },
};
</script>

<template>
  <div>
    <span ref="agentur-name-span" v-if="agentur" class="text-nowrap"
      ><b>{{ agentur.id }}</b>
    </span>
    <b-popover
      :target="() => $refs['agentur-name-span']"
      triggers="hover"
      placement="bottom"
      boundary="window"
    >
      <table class="table table-sm table-borderless">
        <thead>
          <tr>
            <th class="p-0"></th>
            <th class="p-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-muted">Agentur<wbr />nummer:</td>
            <td id="foo">
              <ClickToCopyText :value="agentur.id" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('AUTH.INPUT.EMAIL') }}:</td>
            <td>
              <ClickToCopyText :value="agentur.email" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('COMMON.TELEFON') }}:</td>
            <td>
              <ClickToCopyText :value="agentur.telefon" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('COMMON.FAX') }}:</td>
            <td>
              <ClickToCopyText :value="agentur.fax" />
            </td>
          </tr>
        </tbody>
      </table>
    </b-popover>
  </div>
</template>

<style scoped>
:deep(.popover-body) {
}
</style>

<script>
import ClickToCopyText from '@/components/common/click-to-copy-text.vue';
export default {
  components: {
    ClickToCopyText,
  },
  props: {
    agentur: { type: Object },
  },
  computed: {},
};
</script>

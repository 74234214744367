<template>
  <div class="overflow-auto scroll scroll-pull" data-scroll="true" data-wheel-propagation="true">
    <b-table
      id="vorgaengeTable"
      ref="vorgaengeTable"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="filteredFields"
      :outlined="isOutlined"
      :sort-direction="sortDirection"
      :sort-by.sync="sortBy"
      :sort-compare="sortCompare"
      :busy.sync="isLoading"
      primary-key="id"
      details-td-class="p-0"
      show-empty
      :empty-filtered-text="$t('COMMON.EMPTYFILTEREDTEXT', { name: 'Vorgänge' })"
      :empty-text="isLoading ? 'Laden...' : $t('COMMON.EMPTYTEXT', { name: 'Vorgänge' })"
      thead-tr-class="border-bottom"
      table-class="table table-standard mb-0"
      :selectable="isTableSelectable"
      @sort-changed="onSortChanged"
      @row-selected="onRowSelected"
      @row-clicked="onRowClicked"
    >
      <template #cell(id)="{ item, index }">
        <div class="no-wrap">
          {{ item.id }}
          <span
            v-b-tooltip.hover
            :title="$t('VORGAENGE.STORNIERTERVORGANG')"
            class="bg-warning rounded p-1"
            v-if="isStornierteBuchungDisplayed(item)"
          >
            <i class="flaticon2-exclamation icon-1x text-white"></i>
          </span>
          <span v-b-tooltip.hover title="Option" class="bg-primary rounded p-1" v-if="isOptionFlight(item)">
            <span class="text-white p-1" style="font-size: 1rem">O</span>
          </span>
          <span
            @click="onClickMultipleReisetermine(item.hotelleistungen, reiseterminId)"
            :title="$t('VORGAENGE.MEHREREHOTELLEISTUNGENAUFDIESEMVORGANG')"
            class="bg-success bg-hover-dark rounded p-1"
            v-if="reiseterminId && item.hotelleistungen.length > 1"
          >
            <i class="flaticon2-exclamation icon-1x text-white"></i>
          </span>
        </div>
      </template>

      <template #cell(pnr)="{ item, index }">
        {{ item.pnr.join(',') }}
      </template>

      <template #cell(zimmer)="{ item, index }">
        <template v-if="reiseterminId">
          {{ getZimmerByHotelleistungenWithReiseterminId(item.hotelleistungen) }}
        </template>
        <template v-else>{{ summarizeZimmer(item.zimmer) }}</template>
      </template>

      <template #cell(reisezeitraum)="{ item, index }">
        {{ item.reisedatum | date }} - {{ item.enddatum | date }}
      </template>

      <template #cell(kunde)="{ item, index }">
        <template v-if="item.kunde">
          <kunden-name-popover :kunde="item.kunde.master"></kunden-name-popover>
        </template>
        <div v-else>-</div>
      </template>

      <template #cell(agentur)="{ item, index }">
        <template v-if="item.agentur">
          <agentur-popover :agentur="item.agentur"></agentur-popover>
        </template>
        <div v-else>-</div>
      </template>

      <template #cell(reiseterminkuerzel)="{ item, index }">
        <template v-if="item.hotelleistungen && item.hotelleistungen.length > 0">
          <router-link
            custom
            v-for="(hotelleistung, index) in item.hotelleistungen"
            :key="index"
            :to="'/reisetermine/' + hotelleistung.reisetermin.id"
          >
            <b-badge class="" variant="secondary">
              <a :href="'/reisetermine/' + hotelleistung.reisetermin.id">
                {{ hotelleistung.reisetermin.reiseterminkuerzel }}
              </a>
            </b-badge>
          </router-link>
        </template>
        <div v-else>{{ $t('VORGAENGE.KEINEHOTELLEISTUNGENAUFDIESEMVORGANG') }}</div>
      </template>

      <template #cell(npx)="{ item, index }">
        <b-badge variant="secondary">{{ item.npx }}</b-badge>
      </template>

      <template #cell(teilnehmercell)="{ item, index }">
        {{ getTeilnehmerNamesAndFlightNumbers(item.teilnehmer) }}
      </template>

      <template #cell(thWert)="{ item }">
        <slot :item="item" name="thWertSlot"></slot>
      </template>

      <template #cell(flugticketsAusgestellt)="{ item, index }">
        <div
          :title="'Anreise Art: ' + item.eigenanreiseArt ? item.eigenanreiseArt : item.anreiseart"
          v-b-popover.hover.top="
            item.eigenanreiseArt ? item.eigenanreiseText : 'Keine zusätzlichen Informationen vorhanden.'
          "
          class="text-center"
        >
          <span v-if="item.anreiseart === 'KeinFlug'">{{ $t('VORGAENGE.KEINFLUG') }}</span>
          <span v-else-if="item.anreiseart === 'Eigenanreise'">
            {{ $t('VORGAENGE.EIGENANREISE') }}
            <template v-if="item.eigenanreiseArt">({{ item.eigenanreiseArt }})</template>
            <b-icon-info-circle></b-icon-info-circle>
          </span>
          <span v-else class="text-dark-75 font-weight-bolder d-block font-size-lg">
            <CustomIconChecked :value="item.flugticketAusgestellt" />
          </span>
        </div>
      </template>

      <template #cell(ticketingTimelimit)="{ item, index }">
        <div class="text-center">
          <template v-if="item.ticketingTimelimit">
            <span v-if="item.ticketingTimelimit.length > 20">
              <b-button
                class="btn btn-light-primary btn-icon btn-xs p-0 ml-1"
                variant="primary"
                v-b-popover.hover.top="item.ticketingTimelimit"
                title="Ticketing Timelimit"
              >
                <b-icon-info-circle></b-icon-info-circle>
              </b-button>
            </span>
            <span v-else>
              {{ item.ticketingTimelimit }}
            </span>
          </template>
          <span v-else class="text-dark-75 font-weight-bolder d-block font-size-lg">
            <i class="fas fa-times text-danger"></i>
          </span>
        </div>
      </template>

      <template #cell(stateSlot)="{ item, index }">
        <slot :item="item" name="stateSlot"></slot>
      </template>

      <template #cell(isReleased)="{ item, index }">
        <CustomIconChecked v-if="isVorgangBuchung(item)" :value="item.isReleased" />
      </template>

      <template #head(releaseprocessActions1)="{ item, index }">
        <slot name="releaseprocessActions1Head"></slot>
      </template>
      <template #cell(releaseprocessActions1)="{ item, index }">
        <slot
          :vorgangState="item.blankState"
          :isVorgangBuchung="isVorgangBuchung(item)"
          :isStornierteBuchung="isStornierteBuchung(item)"
          :vorgangId="item.id"
          :vorgang="item"
          name="releaseprocessActions1"
        ></slot>
      </template>

      <template #cell(releaseprocessActions2)="{ item, index }">
        <slot
          :vorgangState="item.blankState"
          :isVorgangBuchung="isVorgangBuchung(item)"
          :isStornierteBuchung="isStornierteBuchung(item)"
          :vorgangId="item.id"
          :vorgang="item"
          name="releaseprocessActions2"
        ></slot>
      </template>

      <template #cell(releaseprocessActions3)="{ item, index }">
        <slot
          :vorgangState="item.blankState"
          :isVorgangBuchung="isVorgangBuchung(item)"
          :isStornierteBuchung="isStornierteBuchung(item)"
          :vorgangId="item.id"
          :vorgang="item"
          name="releaseprocessActions3"
        ></slot>
      </template>

      <template v-slot:cell(releaseProzessState)="{ item, index }">
        <template>
          <VorgangReleaseprocessProgressBar
            :vorgang="item"
            :anreiseart="item.anreiseart"
            :passdatenCheckRequired="passdatenCheckRequired"
            :dokumenteCheckRequired="dokumenteCheckRequired"
          ></VorgangReleaseprocessProgressBar>
        </template>
      </template>

      <template #cell(thLangstrecke)="{ item }">
        <slot :item="item" name="thLangstreckeSlot"></slot>
      </template>
      <template #cell(thInlandsflug)="{ item }">
        <slot :item="item" name="thInlandsflugSlot"></slot>
      </template>
      <template #cell(thGesamt)="{ item }">
        <slot :item="item" name="thGesamtSlot"></slot>
      </template>

      <template #head(thGesamt)>
        <span>TH Gesamt</span>
        <i
          class="fas fa-asterisk text-danger icon-sm"
          title="Zum Zeitpunkt der Buchung"
          v-b-tooltip.hover
          style="align-self: start; position: absolute"
        ></i>
      </template>
      <template #head(thInlandsflug)>
        <span>TH Inlandsflug</span>
        <i
          class="fas fa-asterisk text-danger icon-sm"
          title="Zum Zeitpunkt der Buchung"
          v-b-tooltip.hover
          style="align-self: start; position: absolute"
        ></i>
      </template>
      <template #head(thLangstrecke)>
        <span>TH Langstrecke</span>
        <i
          class="fas fa-asterisk text-danger icon-sm"
          title="Zum Zeitpunkt der Buchung"
          v-b-tooltip.hover
          style="align-self: start; position: absolute"
        ></i>
      </template>

      <template #row-details="{ item, index }">
        <slot
          :teilnehmer="item.teilnehmer"
          :fluege="item.fluege"
          :isFlightsEqual="item.isFlightsEqual"
          :vorgangId="item.id"
          name="vorgangDetails"
        ></slot>
      </template>
      <template #bottom-row="{ item, index }">
        <td v-if="hiddenItemsCount" colspan="100" class="bg-secondary rounded-bottom p-2 text-center mt-2">
          +{{ hiddenItemsCount }} versteckt
        </td>
      </template>
    </b-table>
    <SKRPagination
      v-if="isPaginationShowing"
      v-model="currentPage"
      :isLoading="isLoading"
      :totalRows="count"
      :currentRows="items.length || 0"
      :perPageOverwrite="perPageOverwrite"
      @pageChange="onPageChange"
    ></SKRPagination>
  </div>
</template>

<script>
import KundenNamePopover from '@/components/kunden/kunden-name-popover.vue';
import AgenturPopover from '@/components/agenturen/agentur-popover.vue';
import VorgangReleaseprocessProgressBar from '@/components/vorgaenge/vorgang-releaseprocess-progress-bar.vue';
import SKRPagination from '@/components/common/skr-pagination.vue';
import CustomIconChecked from '@/components/common/custom-icon-checked.vue';

export default {
  name: 'VorgangTable',
  components: {
    VorgangReleaseprocessProgressBar,
    SKRPagination,
    KundenNamePopover,
    AgenturPopover,
    CustomIconChecked,
  },
  props: {
    perPageOverwrite: { type: Number, required: false },
    hiddenItemsCount: { type: Number, default: null },
    items: { type: Array },
    reiseterminId: { type: Number, default: null },
    count: { type: Number, default: null },
    displayFieldsByKey: { type: Array, default: null },
    isOutlined: { type: Boolean, default: false },
    isPaginationShowing: { type: Boolean, default: false },
    sortDirection: { type: String, default: 'asc' },
    releaseprocessActions1Label: { type: String, default: '' },
    releaseprocessActions2Label: { type: String, default: '' },
    releaseprocessActions3Label: { type: String, default: '' },
    passdatenCheckRequired: { type: Boolean },
    dokumenteCheckRequired: { type: Boolean },
    isLoading: { type: Boolean },
    isTableSelectable: { type: Boolean, default: false },
    rowSelectBlockExpression: { type: Function },
    showingFlights: { type: Boolean, default: false },
  },
  data() {
    return {
      perPage: null,
      currentPage: null,
      selected: [],
      sortBy: 'stateSlot',
      fields: [
        { key: 'id', label: this.$t('COMMON.BNR'), tdClass: 'cw-5', sortable: true },
        { key: 'pnr', label: this.$t('VORGAENGE.PNR'), tdClass: 'cw-5' },
        {
          key: 'airlinePNR',
          label: this.$t('VORGAENGE.AIRLINEPNR'),
          tdClass: 'cw-5',
          formatter: value => {
            return Array.isArray(value) && length > 0 ? value.join(',') : '-';
          },
        },
        {
          key: 'reisezeitraum',
          label: this.$t('REISEN.COMMON.TRAVELPERIOD'),
          tdClass: 'cw-20',
          sortable: true,
        },
        { key: 'kunde', label: this.$t('KUNDEN.KUNDE'), tdClass: 'cw-20' },
        {
          key: 'zimmer',
          label: this.$t('VORGAENGE.ZIMMERTYP'),
          tdClass: 'cw-20',
        },
        { key: 'teilnehmercell', label: this.$t('VORGAENGE.TEILNEHMERNAMEN'), tdClass: 'cw-5' },
        { key: 'thWert', label: 'TH Wert', tdClass: 'cw-5' },
        { key: 'flugticketsAusgestellt', label: this.$t('VORGAENGE.FLUGAUSGESTELLT'), tdClass: 'cw-5' },
        { key: 'ticketingTimelimit', label: this.$t('VORGAENGE.TKTLBIS'), tdClass: 'cw-5' },

        { key: 'npx', label: this.$t('COMMON.PAX'), tdClass: 'cw-5' },
        { key: 'thLangstrecke', label: 'TH Langstrecke', tdClass: 'cw-5' },
        { key: 'thInlandsflug', label: 'TH Inlandsflug', tdClass: 'cw-5' },
        { key: 'thGesamt', label: 'TH Gesamt', tdClass: 'cw-5' },
        { key: 'agentur', label: this.$t('AGENTUREN.AGENTUR'), class: 'text-right', tdClass: 'cw-5' },
        {
          key: 'brutto',
          label: this.$t('REISEN.COMMON.SALES'),
          class: 'text-right',
          tdClass: 'cw-5',
          sortable: true,
          formatter: value => {
            return this.$options.filters.currency(value) || '-';
          },
        },
        {
          key: 'stateSlot',
          label: this.$t('REISEN.COMMON.STATE'),
          sortDirection: 'asc',
          tdClass: 'cw-20',
          sortable: true,
        },
        {
          label: this.releaseprocessActions1Label,
          key: 'releaseprocessActions1',
          tdClass: 'cw-10',
        },
        {
          label: this.releaseprocessActions2Label,
          key: 'releaseprocessActions2',
          tdClass: 'cw-10',
        },
        {
          label: this.releaseprocessActions3Label,
          key: 'releaseprocessActions3',
          tdClass: 'cw-10',
        },
        { label: this.$t('COLUMNNAMES.Reiseterminkuerzel'), key: 'reiseterminkuerzel' },
        { key: 'releaseProzessState', label: this.$t('VORGAENGE.RELEASEPRUEFUNG'), tdClass: 'cw-5' },
        { key: 'isReleased', label: this.$t('REISETERMINE.states.Released'), tdClass: 'cw-5' },
        { key: 'actions', label: this.$t('MENU.ACTIONS'), tdClass: 'cw-5' },
      ],
    };
  },
  watch: {
    triggerSent(v) {
      const index = this.items.findIndex(i => i.id === v.id);
      this.$emit('update-single-item', index, v.id);
    },
  },
  computed: {
    filteredFields() {
      if (this.displayFieldsByKey !== null) {
        return this.fields.filter(f => this.displayFieldsByKey.includes(f.key));
      } else {
        return this.fields;
      }
    },
  },
  methods: {
    isOptionFlight(item) {
      return this.showingFlights && item.blankState === 'Option';
    },
    getTHWerteBeiBuchungForEachVorgang() {
      return this.items.map(item => {
        return item.buchungsdatum;
      });
    },
    getThwerteAtBuchungsdatumByVorgangId(vorgangId, key) {
      const vorgangData = this.thwerteAtBuchungsdatumByVorgangId?.[vorgangId]?.data;
      if (vorgangData && vorgangData[key]) {
        return this.$options.filters.currency(vorgangData[key]);
      }
      return '-';
    },
    getThwerteAtBuchungsdatumByVorgangIdisLoading(vorgangId) {
      return this.thwerteAtBuchungsdatumByVorgangId?.[vorgangId]?.isLoading;
    },
    isStornierteBuchungDisplayed(item) {
      return (
        this.releaseprocessActions1Label !== '' &&
        this.isStornierteBuchung(item) &&
        item?.transferplanungErledigt
      );
    },
    getTeilnehmerNamesAndFlightNumbers(teilnehmer) {
      return teilnehmer
        .map(teilnehmer => {
          if (teilnehmer.teilnehmerFluegeMatchNumber) {
            return `(${teilnehmer.teilnehmerFluegeMatchNumber}) ${teilnehmer.name}`;
          } else {
            return teilnehmer.name;
          }
        })
        .join(',');
    },
    onClickMultipleReisetermine(hotelleistungen, reiseterminId) {
      hotelleistungen
        .filter(hotelleistung => hotelleistung.reiseterminId !== reiseterminId)
        .forEach(hotelleistung => {
          let routeData = this.$router.resolve({
            name: 'reisetermine-view',
            params: { reiseterminId: hotelleistung.reiseterminId },
          });
          window.open(routeData.href, '_blank');
        });
    },
    getZimmerByHotelleistungenWithReiseterminId(hotelleistungen) {
      return this.summarizeZimmer(
        hotelleistungen
          .filter(hotelleistung => hotelleistung.reiseterminId === this.reiseterminId)
          .map(hotelleistungen => hotelleistungen.unit)
          .flat()
      );
    },
    summarizeZimmer(zimmer) {
      // Summarizes the roomtypes
      // Input [EZ,EZ,DZ,EZ,TZ]
      // Output 3xEZ,1xEZ,1xTZ
      return (
        Object.entries(
          zimmer.reduce((acc, cur) => {
            acc[cur] = acc[cur] + 1 || 1;
            return acc;
          }, {})
        )
          .map(a => a[1] + 'x ' + a[0])
          .join(',') || '-'
      );
    },
    onRowClicked(item, index) {
      if (!this.isTableSelectable) {
        return;
      }
      if (this.rowSelectBlockExpression(item)) {
        this.$nextTick(() => {
          this.$refs.vorgaengeTable.unselectRow(index);
        });
      }
    },
    onRowSelected(items) {
      this.selected = items;
      this.$emit('row-selected', items);
    },
    onSortChanged($event) {
      this.$emit('sort-changed', $event);
    },
    onPageChange($event) {
      this.$emit('page-changed', $event);
    },
    sortCompare(aRow, bRow, key) {
      if (key === 'stateSlot') {
        const statesOrder = {
          Buchung: 1,
          BuchungReleased: 2,
          Option: 3,
          Anfrage: 4,
          Vormerkung: 5,
          Warteliste: 6,
          StornierteBuchung: 7,
          StornierteOption: 8,
          StornierteAnfrage: 9,
          StornierteVormerkung: 10,
          StornierteWarteliste: 11,
          Unbekannt: 12,
        };
        const a = statesOrder[aRow.blankState] || 0;
        const b = statesOrder[bRow.blankState] || 0;
        return a < b ? -1 : a > b ? 1 : 0;
      } else if (key === 'reisedatum' || key === 'reisezeitraum') {
        return new Date(bRow.reisedatum) - new Date(aRow.reisedatum);
      }
    },
    isVorgangBuchung(vorgang) {
      return vorgang?.blankState === 'Buchung';
    },
    isStornierteBuchung(vorgang) {
      return vorgang?.blankState === 'StornierteBuchung';
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.cw-5) {
  width: 5%;
}
:deep(.cw-10) {
  width: 10%;
}
:deep(.cw-20) {
  width: 20%;
}
.tooltip.show {
  opacity: 1 !important;
}
</style>

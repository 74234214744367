<template>
  <div>
    <span class="text-hover-primary copy-text" @click="onClick">
      {{ value || '-' }}
    </span>
    <input type="hidden" :value="value" ref="hiddenCopyFallbackInput" />
  </div>
</template>

<style scoped>
.copy-text {
  cursor: copy;
}
</style>
<script>
export default {
  name: 'ClickToCopyText',
  props: {
    value: { type: [String, Number] },
  },
  methods: {
    onClick($event) {
      this.copyToClipboard(this.value);
    },
    copyToClipboard(value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          this.toast('Kopiert!');
        })
        .catch(err => {
          this.copyToClipboardFallback();
        });
    },
    copyToClipboardFallback() {
      const el = this.$refs['hiddenCopyFallbackInput'];
      el.type = 'text';
      el.select();
      document.execCommand('copy');
      el.type = 'hidden';
      this.toast('Kopiert!');
    },
  },
};
</script>

<template>
  <i :class="[value ? 'fas fa-check text-success' : 'fas fa-times text-danger', size]"></i>
</template>

<script>
export default {
  name: 'CustomIconChecked',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: 'fa-lg',
    },
  },
};
</script>
